import React from 'react'
import Layout from '../components/layouts/page'
import { Helmet } from 'react-helmet'

const FAQ = () => {
	return (
    <div className="page">
    <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Frequently Asked Limo Questions - Devine Ride</title>
    <meta name="description" content="Answers to all of your common questions about limos. How much does a limo cost? How soon do I need to make a reservation? etc."/>
    </Helmet>
		<Layout>
			<section>
        <div className='container-xxl'>
          <div className='d-flex justify-content-center'>
            <div className='col-md-10 text-center py-5 border-bottom'>
              <div className='py-5'>
                <h1 className='display-1 fw-medium mb-4'>FAQs</h1>
                <p className='fs-5 mb-4'>All the questions you have answered in one place.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container-xxl'>
          <div className='d-flex justify-content-center'>
            <div className='col-12 col-md-10 py-5 border-bottom'>
              <div className='py-5'>
                <h2 className='display-2 fw-medium mb-5'>General</h2>
                <div className='row row-cols-1 row-cols-lg-2 g-4'>
                  <div>
                    <p className='fs-5 fw-bold mb-1'>When are you open?</p>
                    <p>We are available for service 24 hours a day, 7 days a week and are available for new reservations between 7:00 am and 9:00 pm.</p>
                  </div>
                  <div>
                    <p className='fs-5 fw-bold mb-1'>How far in advance do I need to make a reservation?</p>
                    <p>Although we are often able to accommodate ASAP service (within the hour of service requested), we typically advise atleast 24 hour notice. For the best rate, we reccomend booking in as far as in advance as you can.</p>
                  </div>
                  <div>
                    <p className='fs-5 fw-bold mb-1'>What is your cancellation policy?</p>
                    <p>Our cancellation policy varies based on the day of service, the service area, vehicles used, and the original point of service. When you make a reservation, the cancellation policy for your booking will be noted on the invoice.</p>
                    <p>In general though, cancellations made more than 48 hours in advance will receive a full refund.</p>
                  </div>
                  <div>
                    <p className='fs-5 fw-bold mb-1'>What is your no show policy?</p>
                    <p>A reservation is considered a "No show" if the passenger cannot be located or contacted within one hour for airport pickups (time starts when the plane lands) or one hour for all other pickup locations. No shows will be charged the full fare including gratuities.</p>
                  </div>
                  <div>
                    <p className='fs-5 fw-bold mb-1'>Where is your service area?</p>
                    <p>We service Los Angeles, Orange, and San Diego County.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='rates'>
        <div className='container-xxl'>
          <div className='d-flex justify-content-center'>
            <div className='col-12 col-md-10 py-5 border-bottom'>
              <div className='py-5'>
                <h2 className='display-2 fw-medium mb-5'>Pricing and fees</h2>
                <div className='mb-4'>
                  <p className='fs-5 fw-bold mb-3'>How much does a limo cost?</p>
                  <div className='container-xxl mb-2 pricing-table'>
                    <div className='row bg-light d-none d-lg-flex'>
                      <div className='col p-3'></div>
                      <div className='col p-3'>Hourly Rate</div>
                      <div className='col p-3'>Minimum hours</div>
                      <div className='col p-3'>Passenger capacity</div>
                    </div>
                    <div className='row flex-column flex-lg-row'>
                      <div className='col bg-light p-3'>The Road King</div>
                      <div className='col p-3'>$200 - $250 per hour</div>
                      <div className='col p-3'>5 hours minimum</div>
                      <div className='col p-3'>7 passengers</div>
                    </div>
                    <div className='row flex-column flex-lg-row'>
                      <div className='col bg-light p-3'>Corporate Shuttle</div>
                      <div className='col p-3'>$100 - $125 per hour</div>
                      <div className='col p-3'>5 hours minimum</div>
                      <div className='col p-3'>14 passengers</div>
                    </div>
                    <div className='row flex-column flex-lg-row'>
                      <div className='col bg-light p-3'>The Night Rider</div>
                      <div className='col p-3'>$125 - $175 per hour</div>
                      <div className='col p-3'>5 hours minimum</div>
                      <div className='col p-3'>14 passengers</div>
                    </div>
                    <div className='row flex-column flex-lg-row'>
                      <div className='col bg-light p-3'>The Weekender</div>
                      <div className='col p-3'>$150 - $200 per hour</div>
                      <div className='col p-3'>5 hours minimum</div>
                      <div className='col p-3'>8 passengers</div>
                    </div>
                  </div>
                  <p>Looking for a quote for limos in Southern California? <a href='/quote' className='text-decoration-none'>Contact us ›</a></p>
                </div>
                <div>
                  <p className='fs-5 fw-bold mb-3'>What fees do you charge?</p>
                  <div className='row row-cols-1 row-cols-lg-2 gx-4'>
                    <div>
                      <div className='border-start border-top border-dark'>
                        <div className='d-flex flex-column flex-md-row border-bottom border-dark'>
                          <div className='col-12 col-md-6 p-3 bg-light border-end border-dark'>Fuel</div>
                          <div className='col-12 col-md-6 p-3 border-end border-dark'>10%</div>
                        </div>
                        <div className='d-flex flex-column flex-md-row border-bottom border-dark'>
                          <div className='col-12 col-md-6 p-3 bg-light border-end border-dark'>Gratuity</div>
                          <div className='col-12 col-md-6 p-3 border-end border-dark'>15% - 20%</div>
                        </div>
                        <div className='d-flex flex-column flex-md-row border-bottom border-dark'>
                          <div className='col-12 col-md-6 p-3 bg-light border-end border-dark'>Airport fees</div>
                          <div className='col-12 col-md-6 p-3 border-end border-dark'>$15 - $25</div>
                        </div>
                        <div className='d-flex flex-column flex-md-row border-bottom border-dark'>
                          <div className='col-12 col-md-6 p-3 bg-light border-end border-dark'>Administration</div>
                          <div className='col-12 col-md-6 p-3 border-end border-dark'>5%</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='border-start border-top border-dark'>
                        <div className='d-flex flex-column flex-md-row border-bottom border-dark'>
                          <div className='col-12 col-md-6 p-3 bg-light border-end border-dark'>Pets</div>
                          <div className='col-12 col-md-6 p-3 border-end border-dark'>$50 cleaning fee</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
    </div>
  )
}

export default FAQ